import React from 'react'

const ContactForm = () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-half">
        <form
          action="https://formspree.io/contact@mistergoodbeer.com"
          method="POST"
          className="row"
        >
          <div className="field">
            <label className="label" htmlFor="_replyto">
              Email
              <div className="control">
                <input
                  id="_replyto"
                  name="_replyto"
                  className="input form-control"
                  type="email"
                  placeholder="Votre email pour pouvoir vous recontacter"
                  required
                />
              </div>
            </label>
          </div>

          <div className="field">
            <label className="label" htmlFor="name">
              Nom
              <div className="control">
                <input
                  id="name"
                  name="name"
                  className="input form-control"
                  type="text"
                  placeholder="Votre nom"
                  required
                />
              </div>
            </label>
          </div>

          <div className="field">
            <label className="label" htmlFor="message">
              Message
              <div className="control">
                <textarea
                  className="textarea form-control"
                  name="message"
                  id="message"
                  placeholder="Votre message"
                  required
                />
              </div>
            </label>
          </div>

          <div className="columns is-centered">
            <div className="column is-half">
              <div className="control has-text-centered">
                <button
                  className="button is-link is-fullwidth"
                  type="submit"
                >
                  Envoyer
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
)

export default ContactForm
