import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import ContactForm from '../components/ContactForm'


const META = {
  TITLE: 'MisterGoodBeer - Nous contacter',
  DESCRIPTION: 'Contacter l\'équipe de MisterGoodBeer',
  KEYWORDS: 'MisterGoodBeer bières pas chères bars pas chers happy hour contact',
}

const Contact = () => (
  <Layout>
    <Helmet
      title={META.TITLE}
      meta={[
        { name: 'description', content: META.DESCRIPTION },
        { name: 'keywords', content: META.KEYWORDS },
      ]}
    />

    <section>
      <div className="container">
        <div className="content">
          <h1>
            Nous contacter
          </h1>
          <p>
            Pour toute demande ou réclamation, vous pouvez nous contacter en
            remplissant les champs ci-dessous
          </p>
          <ContactForm />
        </div>
      </div>
    </section>

  </Layout>
)

export default Contact
